import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contact-us"} />
		<Helmet>
			<title>
				Спортивний бар Вибагливий Хлоп
			</title>
			<meta name={"description"} content={"Де кожен ігровий вечір - це хоум-ран\n"} />
			<meta property={"og:title"} content={"Спортивний бар Вибагливий Хлоп"} />
			<meta property={"og:description"} content={"Де кожен ігровий вечір - це хоум-ран\n"} />
			<meta property={"og:image"} content={"https://carnitahustin.com/media/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://carnitahustin.com/media/7128098.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://carnitahustin.com/media/7128098.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://carnitahustin.com/media/7128098.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://carnitahustin.com/media/7128098.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://carnitahustin.com/media/7128098.png"} />
			<meta name={"msapplication-TileImage"} content={"https://carnitahustin.com/media/7128098.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" color="--darkD2" quarkly-title="Contacts-8" display="block">
			<Override
				slot="SectionContent"
				align-items="center"
				lg-flex-direction="column"
				justify-content="center"
				display="flex"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				width="90%"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				justify-content="center"
				lg-margin="0px 0 30px 0px"
				align-items="flex-start"
				sm-margin="0px 0px 0 0px"
				lg-padding="0px 180px 0px 0px"
				sm-padding="0px 0 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 20px 0px" font="normal 600 38px/1.2 --fontFamily-sansHelvetica" color="--dark">
					Зв'язок
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="normal 300 16px/1.5 --fontFamily-sansHelvetica"
					color="--darkD2"
					lg-margin="0px 0px 20px 0px"
					md-width="100%"
				>
					Дізнавайтеся першими про наші унікальні події, спеціальні пропозиції та ексклюзивні пропозиції.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="16px 24px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-gap="36px 34px"
				md-grid-template-columns="1fr"
				flex-direction="column"
				lg-margin="0px 0 0 0"
				width="80%"
				justify-content="center"
				align-items="flex-start"
				align-self="flex-start"
				padding="0px 0px 0px 20px"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdLocationOn}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:+9877654321223"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							пр. Злуки 8, м. Тернопіль, 46027
						</Link>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 16px/1.2 --fontFamily-sans"
							text-align="center"
							color="--darkD2"
							md-margin="0px 0px 0px 0px"
						>
							{" "}Адреса
						</Text>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
					margin="0px 0px 30px 0px"
					lg-margin="0px 0px 10px 0px"
					sm-margin="0px 0px 0 0px"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="tel:+38 068 409 78 82"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							+38 068 409 78 82
						</Link>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 16px/1.2 --fontFamily-sans"
							text-align="center"
							color="--darkD2"
							md-margin="0px 0px 0px 0px"
						>
							телефон
						</Text>
					</Box>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="row"
					border-color="--color-lightD2"
					border-radius="4px"
					align-items="center"
				>
					<Icon
						category="md"
						icon={MdMailOutline}
						size="54px"
						margin="0px 20px 0 0px"
						color="--darkL1"
						background="--color-light"
						padding="12px 12px 12px 12px"
						border-radius="50%"
					/>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
						md-justify-content="center"
					>
						<Link
							href="mailto:info@carnitahustin.com"
							color="--dark"
							text-decoration-line="initial"
							font="normal 500 25px/1.5 --fontFamily-sansHelvetica"
							display="block"
							margin="0px 0px 5px 0px"
							sm-font="normal 500 22px/1.5 --fontFamily-sansHelvetica"
						>
							info@carnitahustin.com
						</Link>
						<Text
							margin="0px 0px 0 0px"
							font="normal 300 16px/1.2 --fontFamily-sans"
							text-align="center"
							color="--darkD2"
							md-margin="0px 0px 0px 0px"
						>
							Email
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});